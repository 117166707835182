.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  padding-top: $navbar-height;

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 1.1rem 2.5rem 2.5rem;
  width: 100%;
  @include flex-grow(1);
  @media(max-width: 767px) {
    padding: 1.5rem 1.5rem;
  }
}