// Pagination variations
@mixin pagination-variants($color) {
  .page-item {
    .page-link {
      background: rgba($color, .08);
      border: 0;
      color: $color;
    }
    &.active {
      .page-link {
        background: $color;
        border-color: $color;
      }
    }
    &:hover {
      .page-link {
        background: lighten($color,5%);
        border-color: $color;
        color: $white;
      }
    }
  }
}