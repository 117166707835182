/* Dropdowns */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "Material Design Icons";
      content: "\F54F";  
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: .625rem;
      font-size: 0.937rem;
      margin-left: 0;
    }
  }
  .dropdown-menu {
    margin-top: 0;
    font-size: $default-font-size;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); 
    border-radius: 0;   
    .dropdown-item {
      font-size: .875rem;
      padding: .25rem 1.5rem;
      &:active {
        background: initial;
      }
    }
  }
}

.btn-group {
  .dropdown-toggle {
    &.dropdown-toggle-split {
      padding-right: .5rem;
      padding-left: .5rem;
    }
    &::after {
      content: '\F140';
      font: normal normal normal 18px/1 "Material Design Icons";
      border: 0;
      vertical-align: unset;
    }
  }
  &.dropup {
    .dropdown-toggle {
      &::after {
        content: '\F143';
      }
    }
  }
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      &.dropdown-toggle {
        &::before {
          content: '';
          height: 18px;
          width: 1px;
          position: absolute;
          left: 0;
          background: lighten($value , 10%);
        }  
      }
    }
  }
}
